import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository'
import FileDialogButtonView from '@ckeditor/ckeditor5-upload/src/ui/filedialogbuttonview'

export default class FileUploadPlugin extends Plugin {
	static get requires() {
		return [FileRepository]
	}

	static get pluginName() {
		return 'FileUploadPlugin'
	}

	static get defaultConfig() {
		return {
			uploadEndpoint: '/default-upload-endpoint', // Значение по умолчанию для `endpoint`
		}
	}

	init() {
		const editor = this.editor
		const t = editor.t
		const fileRepository = editor.plugins.get(FileRepository)

		// Получаем URL для загрузки из конфигурации
		const uploadEndpoint = editor.config.get('fileUpload.uploadEndpoint')

		editor.ui.componentFactory.add('fileUpload', locale => {
			const view = new FileDialogButtonView(locale)

			view.set({
				acceptedType: '*/*', // Разрешаем все типы файлов
				allowMultipleFiles: false,
			})

			view.buttonView.set({
				label: t('Upload File'),
				icon: `
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                            <path d="M1443.6,4990.4c-26.9-11.5-69.2-38.4-92.2-61.5c-107.6-99.9-99.9,232.5-99.9-4823.9v-4677.8l42.3-63.4c25-34.6,69.2-82.6,98-103.8c53.8-40.3,57.7-40.3,1966.1-40.3h1910.3l51.9,53.8c51.9,50,53.8,59.6,53.8,246v194.1l-63.4,55.7l-61.5,55.7H3557.7H1866.4V110.8v4285.8H3894h2027.6v-945.6c0-622.7,7.7-974.4,21.2-1024.3c11.5-42.3,44.2-101.9,71.1-132.6c101.9-107.6,92.2-107.6,1147.4-107.6h970.5V716.1c0-1633.6-5.8-1560.6,124.9-1616.3c84.6-34.6,280.6-34.6,363.2,0c136.4,55.7,126.8-67.3,126.8,1795c0,1620.1-1.9,1679.7-36.5,1748.9c-55.7,105.7-2262,2300.5-2350.4,2338.9c-63.4,25-351.7,28.8-2469.6,26.9C2345,5009.6,1474.4,5001.9,1443.6,4990.4z M7132.3,3368.3l567-567h-572.7h-570.8v567c0,311.3,1.9,567,5.8,567C6563.4,3935.3,6821,3679.6,7132.3,3368.3z"/><path d="M6242.5-1670.8c-411.3-411.3-772.6-784.1-803.3-830.2c-157.6-242.1,55.7-536.2,334.4-463.2c69.2,19.2,144.1,86.5,522.7,463.2c244.1,242.2,449.7,440.1,457.4,440.1c5.8,0,15.4-572.7,17.3-1272.3c5.8-1239.6,7.7-1276.1,44.2-1326.1c48-67.3,174.9-130.7,257.5-130.7c90.3,0,203.7,65.4,253.7,148l46.1,73l5.8,1256.9l5.8,1258.8l445.9-443.9c388.2-388.2,457.4-447.8,532.4-467c251.8-67.3,468.9,192.2,355.5,430.5c-15.4,32.7-376.7,407.4-803.3,834.1C7157.3-944.4,7140-927.1,7065-927.1S6972.8-944.4,6242.5-1670.8z"/>
                        </g>
                    </svg>
                `,
				tooltip: true,
			})

			view.on('done', (evt, files) => {
				const loader = fileRepository.createLoader(files[0])

				if (!loader) {
					return
				}

				this._uploadFile(loader, uploadEndpoint)
			})

			return view
		})
	}

	_uploadFile(loader, uploadEndpoint) {
		console.log('Loader:', loader)
		console.log('Loader file:', loader.file)
		console.log('Resolve method:', loader.resolve)
		console.log('Reject method:', loader.reject)
		const xhr = new XMLHttpRequest()

		xhr.open('POST', uploadEndpoint, true)
		xhr.responseType = 'text' // Получаем текстовый ответ для дальнейшей обработки

		xhr.addEventListener('load', () => {
			if (xhr.status < 200 || xhr.status >= 300) {
				return loader.reject(`Upload failed with status: ${xhr.status}`)
			}

			let responseText = xhr.responseText || xhr.response
			console.log(xhr.response, xhr.responseText)
			// Удаление всех HTML тегов
			responseText = responseText.replace(/<\/?[^>]+(>|$)/g, '')

			let response

			try {
				response = JSON.parse(responseText)
			} catch (e) {
				return console.log('Failed to parse server response as JSON.')
			}

			if (response.url) {
				loader.file.then(file => {
					this.editor.model.change(writer => {
						const route = response.url
						const protocol = window.location.protocol
							? window.location.protocol
							: 'https:'

						const url = `${protocol}//${window.location.hostname}${route}`
						console.log('File URL:', url) // Логируем URL файла

						const fileElement = writer.createText(file.name)
						console.log('File element:', fileElement) // Логируем созданный элемент
						writer.setAttribute('linkHref', url, fileElement)
						this.editor.model.insertContent(
							fileElement,
							this.editor.model.document.selection
						)

						console.log('File element created and inserted into the editor.') // Логируем создание и вставку элемента
						console.log('Editor content:', this.editor.getData())
					})
					this.editor.updateSourceElement && this.editor.updateSourceElement()

					if (loader && loader.resolve) {
						loader.resolve()
					}
				})
			} else {
				if (loader && loader.reject) {
					loader.reject('Server response does not contain a URL.')
				}
			}
		})

		xhr.addEventListener('error', () => {
			if (loader && loader.reject) {
				loader.reject('Upload failed.')
			}
		})

		xhr.addEventListener('abort', () => {
			if (loader && loader.reject) {
				loader.reject('Upload aborted.')
			}
		})

		loader.file.then(file => {
			const data = new FormData()
			data.append('file', file)

			xhr.send(data)
		})
	}
}
